import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import {
  VideoSection,
  Process,
  Contact,
} from 'components/windrate';

import pagedata from "content/pages/how-it-works.yaml";
import contact from "content/global/contact.yaml";

const useStyles = makeStyles(theme => ({
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  sectionAlternate: {
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, ${theme.palette.background.paper} 400px, ${theme.palette.primary.dark} 0%)`,
  },   
}));

const HowItWorks = () => {
  const classes = useStyles();

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
      <Divider />  
      </Section>
      <Section className={classes.pagePaddingTop}>
        <VideoSection data={pagedata.videoSection}/>
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <Process data={pagedata.process} />
      </Section>
      <Divider />
      <SectionAlternate className={classes.sectionAlternate}>
        <Contact data={contact} />
      </SectionAlternate>
    </div>
  );
};

export default HowItWorks;
